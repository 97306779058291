

























































































































































































































































































































































































































































    import {
        Vue, Component, Watch,
    } from 'vue-property-decorator'
    import { State } from 'vuex-class'
    import Cookies from 'js-cookie'
    import api from '~/api'
    import { encryptPassword } from '~/utils/encryption'
    import common from '~/utils/common'
    import storage from '~/utils/storage'
    import CommonCheckPasswordInput from '~/components/common-check-password-input.vue'
    import CaptchaVerify from '~/components/CaptchaVerify.vue'
    import policyData from '~/utils/policy'
    import utils from '~/utils'

@Component({
    components: {
        CommonCheckPasswordInput,
        CaptchaVerify
    },
})
    export default class LoginBox extends Vue {
    @State('langCode') langCode

    @State('loginModalVisible') loginModalVisible

    @State('isChangePassword') isChangePassword

    @State('userInfo') userInfo

    @State('userId') userId

    @State('countryId') countryId

    $refs!: {
        resetCheckPasswordInput: any,
        setupCheckPasswordInput: any,
        addressFormRef: any
    }

    seletedMethod: string = 'OTP'

    otpLoginForm: any = {
        mobileNumber: '',
        isValidMobileNumber: false,
        isActiveUser: true,
        isActiveUserTips: '',
        verificationCode: '',
        isValidVerificationCode: true,
        showTextInSendBtn: '',
        timer: null,
    }

    psdLoginForm: any = {
        mobileNumber: '',
        isValidMobileNumber: false,
        isRegisteredMobileNumber: true,
        password: '',
        isWrongPassword: false,
        isRegisteredMobileNumberTips: '',
        isActiveUser: true,
    }

    conditionChecked: boolean = true

    setupForm: any = {
        setupPsd: '',
    }

    resetForm: any = {
        mobileNumber: '',
        isValidMobileNumber: false,
        verificationCode: '',
        isValidVerificationCode: true,
        showTextInSendBtn: '',
        resetPsd: '',
        conditionChecked: true,
        timer: null,
    }

    showDefaultLogin: boolean = true            // 默认登录弹窗

    showSetupPsd: boolean = false               // 新用户注册成功后重置密码弹窗show

    showResetPsd: boolean = false              // 忘记密码场景设置新密码弹窗

    agreementList: any[] = []                  // 登录时需要勾选的协议

    countryList: any[] = []                    // 国家列表

    currentCountry: any = {                    // 当前选择的国家
        id: '',
        countryFlag: '',
        areaCode: '',
        mobileRule: null,
    }

    loginApiVerified: number = 0

    protocolList:any = []

    selectedPolicyId:any

    fbSignInParams:any={}

    captchaVerifyVisible:boolean = false

    sendCodeType: string = 'login'

    addressForm: any = {
        contactName: '',
        detailAddress: '',
        district: '',
    }

    addressFormRules: any = {
        contactName: [
            { required: true, message: this.$t('label_contact_placeholder'), trigger: 'blur' },
        ],
        detailAddress: [
            { required: true, message: this.$t('label_detail_address_placeholder'), trigger: 'blur' },
        ],
        district: [
           {
 type: 'array', required: true, message: this.$t('label_district_placeholder'), trigger: ['change', 'blur'],
},
        ],
    }

    addressList:any = []

    lastApplyData: any = {}

    isShowCompleteAddress:boolean = false

    showCompleteAddress:boolean = false

    get isDisableLogin() {
        if (this.seletedMethod === 'OTP') {
            return !this.otpLoginForm.isValidMobileNumber
                || !this.otpLoginForm.verificationCode
                || !this.conditionChecked
                || !this.otpLoginForm.isActiveUser
        }
        if (this.seletedMethod === 'CIAM') {
            return !this.conditionChecked
        }
        return !this.psdLoginForm.isValidMobileNumber
            || !this.psdLoginForm.password
            || !this.conditionChecked
            || !this.psdLoginForm.isRegisteredMobileNumber
    }

    get isDisableSetup() {
        return !this.setupForm.setupPsd
    }

    get isDisableSetNewPsd() {
        return !this.resetForm.isValidMobileNumber
            || !this.resetForm.resetPsd
            || !this.resetForm.conditionChecked
            || !this.resetForm.verificationCode
    }

    get conditionHref() {
        if (this.langCode === 'th-TH') {
            return '/conditions-TH.pdf'
        }
        return '/conditions-EN.pdf'
    }

    mounted() {
        // this.fbSignInParams = { scope: 'email,user_likes', return_scopes: true }
        this.getCIAMCountryInfo()
    }

    async getCIAMCountryInfo() {
            if (Cookies.get('tokenType') === 'CIAM') {
                let countryRes = await api.country_list_get()
                let countryList = countryRes.data
                this.$store.commit('SET_COUNTRY_LIST', countryList)
                let currentCountry = countryList.find(country => +country.id === +this.$store.state.countryId)
                this.$store.commit('SET_CURRENT_COUNTRY', currentCountry)
                this.$store.commit('SET_COUNTRY_CURRENCY', currentCountry.currencyUnit)
                this.countryList = countryRes.data
            }
        }

    getCountryList() {
        // api.country_list_get().then(res => {
        //     this.countryList = (res && res.data) || []
        //     this.currentCountry = this.countryList[0] || {}
        // })
        this.countryList = this.$store.state.countryList
        this.currentCountry = this.$store.state.currentCountry
    }

    openDlg() {
        if (this.$store.state.applyAgainData.cellphone && this.$store.state.isRepeatApply) {
            this.dealLastApplyData()
            return
        }
        let showCompleteAddressInner = false
        let contactName = ''
        if (this.isShowCompleteAddress && !this.showDefaultLogin) {
            this.showCompleteAddress = true
            showCompleteAddressInner = true
            contactName = this.addressForm.contactName
        }
        const data = <any> this.$options.data
        Object.assign(this.$data, data.call(this))
        if (showCompleteAddressInner) {
            this.$data.isShowCompleteAddress = true
            this.$data.showDefaultLogin = false
            this.$data.addressForm.contactName = contactName
        }
        this.otpLoginForm.showTextInSendBtn = this.$t('label_get_code')
        this.resetForm.showTextInSendBtn = this.$t('label_get_code')
        this.getCountryList()
        if (this.userInfo.passwordStatus == 2 && window.localStorage.getItem('setUpBox')) {
            this.showDefaultLogin = false
            this.showSetupPsd = true
        }
        if (this.isChangePassword) {
            this.showDefaultLogin = false
            this.showResetPsd = true
            this.resetForm.mobileNumber = this.userInfo.cellphone
            this.resetForm.isValidMobileNumber = true
        }
        if(this.$route.query.cellphone){
            this.otpLoginForm.mobileNumber = this.$route.query.cellphone
        }

    }

    closeDlg() {
        if (this.showSetupPsd) {
            window.localStorage.removeItem('setUpBox')
        }
        if (this.showCompleteAddress) {
            this.showDefaultLogin = true
            this.isShowCompleteAddress = false
        }
        this.$store.commit('SET_LOGIN_MODAL', false)
        this.$store.commit('SET_CHANGE_PASSWORD', false)
    }

    changeMethod(method: string) {
        this.seletedMethod = method
    }

    clearMobileInput(type) {
        this[type].mobileNumber = ''
    }

    fillVerificationCode() {
        if (this.otpLoginForm.verificationCode.length === 4) {
            utils.fbq('trackCustom', 'OTP_fill_in')
            utils.ga4('event', 'OTP_fill_in')
        }
    }

    sendVerificationCode(type) {
        this.sendCodeType = type
        let operatedForm = this.showResetPsd ? this.resetForm : this.otpLoginForm
        if (operatedForm.timer) {
            return
        }
        this.captchaVerifyVisible = true
    }

    doSendCode(captchaData) {
        if (this.sendCodeType === 'login') {
            utils.fbq('trackCustom', 'OTP_request')
            utils.ga4('event', 'OTP_request')
        }
        let operatedForm = this.showResetPsd ? this.resetForm : this.otpLoginForm
        let params: any = {
            ...captchaData,
            prefix: '+' + this.currentCountry.areaCode,
            cellphone: operatedForm.mobileNumber.trim(),
            cellphoneCode: encryptPassword(operatedForm.mobileNumber.trim()),
        }
        api.client_send_code(params).then(res => {
            let code = +res.code
            this.$notify({
                title: code === (200 || 20049024) ? this.$t('tips_success') : this.$t('tips_error'),
                message: res.message,
                type: code === (200 || 20049024) ? 'success' : 'error',
                position: 'top-right',
            })
            if (code !== (200 || 20049024)) {
                operatedForm.showTextInSendBtn = this.$t('setting_Resend')
                return
            }

            operatedForm.showTextInSendBtn = 60
            operatedForm.timer = setInterval(() => {
                operatedForm.showTextInSendBtn--
                if (operatedForm.showTextInSendBtn === 0) {
                    operatedForm.showTextInSendBtn = this.$t('setting_Resend')
                    clearInterval(operatedForm.timer)
                    operatedForm.timer = null
                }
            }, 1000)
        })
    }

    checkMobileNumber() {
        if (!this.psdLoginForm.isValidMobileNumber) {
            return
        }
        let params = {
            cellphone: this.psdLoginForm.mobileNumber.trim(),
            prefix: this.currentCountry.areaCode,
            checkType: 2
        }

        api.checkout_user_active_status(params).then(res =>{
            if(res.code != 200){
                this.psdLoginForm.isRegisteredMobileNumber = false
                this.psdLoginForm.isRegisteredMobileNumberTips = res.message
            }else{
                this.psdLoginForm.isRegisteredMobileNumber = true
                this.psdLoginForm.isRegisteredMobileNumberTips = ''

            }
        })
    }

    checkActiveUser(){
        if (!this.otpLoginForm.isValidMobileNumber) {
            return
        }
        let params = {
            cellphone: this.otpLoginForm.mobileNumber.trim(),
            prefix: this.currentCountry.areaCode,
            checkType: 1
        }
        api.checkout_user_active_status(params).then(res => {
            if(res.code != 200){
                this.otpLoginForm.isActiveUser = false
                this.otpLoginForm.isActiveUserTips = res.message
            }else{
                this.otpLoginForm.isActiveUser = true
                this.otpLoginForm.isActiveUserTips = ''
            }
        })
    }

    login() {
        const currentCountry = storage.getLocal('currentCountry') || {}
        const needCountryId = currentCountry.id
        storage.setLocal('currentCountry', this.currentCountry)
        // 韩国做特殊处理
        if (this.currentCountry.countryCode === 'KR') {
            if (this.seletedMethod === 'OTP') {
                this.loginByOtpKR()
            } else if (this.seletedMethod === 'CIAM') {
                if (this.$store.state.auditStatus && Cookies.get('tokenType') === 'CIAM') {
                    api.logout_ciam({
                        loginSource: 'EGG_MALL_WEB',
                    }).then(res => {
                            let resData = res.data + '&countryId=' + needCountryId
                            window.location.href = resData
                            window.event.returnValue = false
                            Cookies.remove('tokenType')
                    })
                } else {
                    api.login_CIAM({
                        loginSource: 'EGG_MALL_WEB',
                    }).then(res => {
                        console.log(res)
                        window.open(res.data, '_self')
                    })
                }
        } else {
                this.loginByPsd()
            }
            return
        }

        if (this.seletedMethod === 'OTP') {
            this.loginByOtp()
        } else if (this.seletedMethod === 'CIAM') {
            api.login_CIAM({
                loginSource: 'EGG_MALL_WEB',
            }).then(res => {
                let resData = res.data + '&countryId=' + needCountryId
                console.log(res)
                window.open(resData, '_self')
            })
        } else {
            this.loginByPsd()
        }
    }

    loginByOtpKR() {
        this.otpLoginForm.isValidVerificationCode = true
        let params = {
            cellphone: this.otpLoginForm.mobileNumber,
            prefix: this.currentCountry.areaCode,
            phoneCode: this.otpLoginForm.verificationCode,
        }
        api.client_login_otp(params, {needNotLoginType: true}).then(res => {
            if (res.data && res.data.userId) {
                this.$store.commit('SET_USER_ID', res.data.userId)
            }
            if (res.code == 20049600) {
                // applyStatus对应枚举值说明：
                // 0 - 客户没有申请过，需要提交注册申请
                // 1 - 客户提交了申请，审核中
                // 2 - 审核通过
                // 3 - 拒绝申请
                api.kr_register_status({userId: this.userId}).then(res => {
                    this.$store.commit('SET_COUNTRY_INFO', { countryId: res.data.countryId, countryCode: 'KR' })
                    switch (+res.data.applyStatus) {
                        case 0:
                            this.finishAddressKR(res.data)
                            break
                        case 1:
                            this.showApplyStatusKR(1)
                            break
                        case 2:
                            this.normalLoginKR(res.data)
                            break
                        case 3:
                            this.$store.commit('SET_APPLY_AGAIN_DATA', res.data)
                            this.showApplyStatusKR(2)
                            break
                    }
                })
                return
            }
            if (res.code != 200) {
                this.$notify({
                    title: this.$t('tips_error'),
                    message: res.message,
                    type: 'error',
                    position: 'top-right',
                })
                return
            }
            // 正常登录
            this.normalLoginKR(res.data)
        })
    }

    finishAddressKR(data) {
        api.district_list_get().then(res => {
            let data = (res && res.data) || []
            this.addressList = data
        })
        this.$store.commit('SET_LOGIN_COMPLETE_ADDRESS', false)
        this.isShowCompleteAddress = true
        this.addressForm.contactName = data.contactName
        this.showDefaultLogin = false
    }

    finishAddress() {
        let addressFormRefSet = this.$refs.addressFormRef as any
        addressFormRefSet.validate(valid => {
            if (valid) {
                let params = {
                    prefix: '82',
                    cellphone: this.otpLoginForm.mobileNumber || this.$store.state.userInfo.cellphone,
                    cityId: this.addressForm.district[1],
                    contactName: this.addressForm.contactName,
                    detailAddress: this.addressForm.detailAddress,
                    districtId: this.addressForm.district[2],
                    stateId: this.addressForm.district[0],
                    userId: this.userId,
                    countryId: 1198,
                }
                // 发送之后直接未申请登录页面
                api.kr_apply_register(params).then(() => {
                    this.showApplyStatusKR(1)
                })
            }
        })
    }

    showApplyStatusKR(status) {
        this.$store.commit('SET_LOGIN_MODAL', false)
        if (+status === 2) {
            this.$store.commit('SET_AUDIT_RESULT_STATUS', {loginAuditResultVisible: true, auditStatus: false})
        } else {
            this.$store.commit('SET_AUDIT_RESULT_STATUS', {loginAuditResultVisible: true, auditStatus: true})
        }
    }

    dealLastApplyData() {
        api.district_list_get().then(res => {
            let data = (res && res.data) || []
            this.addressList = data
            this.lastApplyData = JSON.parse(JSON.stringify(this.$store.state.applyAgainData))
            this.$store.commit('SET_AUDIT_RESULT_STATUS', {})
            this.otpLoginForm.mobileNumber = this.lastApplyData.cellphone
            this.addressForm.district = [this.lastApplyData.stateId, this.lastApplyData.cityId, this.lastApplyData.districtId]
            this.addressForm.contactName = this.lastApplyData.contactName
            this.addressForm.detailAddress = this.lastApplyData.detailAddress
            console.log('test', this.addressForm.district)
            this.$store.commit('SET_LOGIN_COMPLETE_ADDRESS', false)
            this.isShowCompleteAddress = true
            this.showDefaultLogin = false
        })
    }

    normalLoginKR(data) {
        this.$store.commit('SET_USER_ID', data.userId)
        this.$store.commit('SET_LOGIN_TOKEN', data.token)
        this.$store.commit('SET_ACCEPT_COOKIES_STATUS', true)
        this.$store.commit('SET_LOGIN_MODAL', false)
        api.client_profile({token: data.token}).then(userData => {
            this.$store.commit('SET_LOGIN_USERINFO', {
                ...userData.data.profile,
                triggerByLogin: true,
            })
            let countryId = this.$store.state.userInfo.countryId
            let countryCode = this.countryList.find(item => item.id === countryId).countryCode
            this.$store.commit('SET_COUNTRY_INFO', { countryId, countryCode })
            setTimeout(() => {
                window.location.reload()
            }, 50)
        })
    }

    loginByOtp() {
        this.otpLoginForm.isValidVerificationCode = true
        let params = {
            cellphone: this.otpLoginForm.mobileNumber,
            prefix: this.currentCountry.areaCode,
            phoneCode: this.otpLoginForm.verificationCode,
        }
        api.client_login_otp(params).then(res => {
            let data = (res && res.data) || {}
            if (res.code == 20040007) {
                this.$notify({
                    title: this.$t('tips_error'),
                    message: this.$t('label_unauthorized_account'),
                    type: 'error',
                    position: 'top-right',
                })
                return
            }
            if (res.code != 200) {
                this.$notify({
                    title: this.$t('tips_error'),
                    message: res.message,
                    type: 'error',
                    position: 'top-right',
                })
                return
            }
            utils.fbq('trackCustom', 'account_registration', {
                customerId: data.userId,
                registrationSource: 'website',
            })
            utils.ga4('event', 'account_registration', {
                customerId: data.userId,
                registrationSource: 'website',
            })
            this.loginApiVerified = data.verified
            window.localStorage.setItem('loginApiVerified', data.verified)
            this.$store.commit('SET_USER_ID', data.userId)
            this.$store.commit('SET_CUSTOMER_TYPE', data.customerTypeName)
            this.$store.commit('SET_LOGIN_TOKEN', data.token)
            if (data.passwordStatus == 2) {
                window.localStorage.setItem('setUpBox', 'show')
            }
            this.getProfile()
            this.$store.commit('SET_ACCEPT_COOKIES_STATUS', true)
        }).catch(err => {
            if (err.code == 1201) {
                this.otpLoginForm.isValidVerificationCode = false
            }
        })
    }

    loginByPsd() {
        let params = {
            cellphone: this.psdLoginForm.mobileNumber,
            prefix: this.currentCountry.areaCode,
            password: encryptPassword(this.psdLoginForm.password),
        }
        api.client_login_userPsd(params).then(res => {
            let data = (res && res.data) || {}
            if (res.code == 200) {
                this.$store.commit('SET_LOGIN_TOKEN', data.token)
                this.$store.dispatch('setUserId')
                this.loginApiVerified = data.verified
                this.getProfile(data.token)
                this.$store.commit('SET_ACCEPT_COOKIES_STATUS', true)
            } else if (res.code == 20040007) {
                this.$notify({
                    title: this.$t('tips_error'),
                    message: this.$t('label_unauthorized_account'),
                    type: 'error',
                    position: 'top-right',
                })
            } else {
                this.$notify({
                    title: this.$t('tips_error'),
                    message: res.message,
                    type: 'error',
                    position: 'top-right',
                })
            }
        }).catch(() => {
            this.psdLoginForm.isWrongPassword = true
        })
    }

    getProfile(token?: string) {
        this.$store.commit('SET_LOGIN_MODAL', false)
        let params:any = {}
        if (token) {
            params.token = token
        }
        api.client_profile(params).then(userData => {
            this.$store.commit('SET_LOGIN_USERINFO', {
                ...userData.data.profile,
                triggerByLogin: true,
                loginApiVerified: this.loginApiVerified,
            })
            this.loginSuccessCallBack()
        }).catch(err => {
            this.$notify({
                title: this.$t('tips_error'),
                message: err.message || 'System Error',
                type: 'error',
                position: 'top-right',
            })
        })
    }

    async loginSuccessCallBack() {
        let countryId = this.$store.state.userInfo.countryId
        let countryCode = this.countryList.find(item => item.id === countryId).countryCode
        this.$store.commit('SET_COUNTRY_INFO', { countryId, countryCode })
        api.get_last_check_price().then(res => {
            if (res.code == 200) {
                let priceIdList = (res && res.data) || []
                let lastCheckPriceIndex = priceIdList.findIndex(item => item.lastCheck)
                if (lastCheckPriceIndex > -1) {
                    common.setPriceId(priceIdList[lastCheckPriceIndex].priceId)
                    this.$store.commit('SET_PRICE_ITEM', JSON.stringify(priceIdList[lastCheckPriceIndex]))
                    if (this.$route.path.indexOf('bannerPromotion') > -1) { // 未登录显示banner进入banner详情h5后唤起登录窗口登录场景
                        let promotionUrl = this.$route.query.url
                        promotionUrl = promotionUrl.replace('token=', `token=${window.localStorage.getItem('token')}`)
                        promotionUrl = promotionUrl.replace('priceId=', `priceId=${this.$store.state.priceItem.priceId}`)
                        this.$router.replace({
                            path: this.localePath({ name: 'bannerPromotionPage' }),
                            query: {
                                url: promotionUrl,
                            },
                        })
                        setTimeout(() => {
                            window.location.reload()
                        }, 50)
                    } else if (Cookies.get('tokenType') != 'CIAM') {
                        setTimeout(() => {
                            window.location.reload()
                        }, 10)
                    } else {

                        if(sessionStorage.getItem('targetPage') && sessionStorage.getItem('targetPage') == '2'){
                            this.$router.push(this.localePath({ name: 'orderProcedurePage' }))
                        }

                        if(sessionStorage.getItem('targetPage') && sessionStorage.getItem('targetPage') == '3'){
                            let externalParams = JSON.parse(this.$route.query.externalParams)
                            let keywords = externalParams.keywords
                            let query = {
                                searchResultInfo: JSON.stringify({keywords}),
                                oeNumberOrBrandSku: keywords,
                                isAccurateSearch: true,
                                brandId: externalParams.brandId
                            }

                            this.$router.push(this.localePath({
                                name: 'multiConditionSearchPage',
                                query
                            }))
                        }

                        setTimeout(() => {
                            window.location.reload()
                        }, 10)
                        }
                } else {
                    api.client_auth_logout().then(() => {
                        this.$store.commit('CLEAR_DATA', this)
                    })
                }
            }
        })
    }

    setup() {
        let password = this.setupForm.setupPsd
        if (this.$refs.setupCheckPasswordInput.checkResult()) {
            api.client_set_psd({
                password: encryptPassword(password),
            }).then(res => {
                if (res.code != 200) {
                    this.$notify({
                        title: this.$t('tips_error'),
                        message: res.message || 'System Error',
                        type: 'error',
                        position: 'top-right',
                    })
                    return
                }

                this.$notify({
                    title: this.$t('tips_success'),
                    message: this.$t('settled_Success'),
                    type: 'success',
                    position: 'top-right',
                })

                this.$store.commit('SET_LOGIN_TOKEN', res.data)
                this.$store.dispatch('setUserId')
                this.showSetupPsd = false
                this.$store.commit('SET_LOGIN_MODAL', false)
                this.$store.commit('SET_LOGIN_USERINFO', {
                    passwordStatus: 1,   // 标记为已重置密码
                })
                this.loginSuccessCallBack()
            })
        }
    }

    confirmResetPsd() {
        let password = this.resetForm.resetPsd
        if (this.$refs.resetCheckPasswordInput.checkResult()) {
            this.resetForm.isValidVerificationCode = true
            let params = {
                password: encryptPassword(password),
                prefix: this.currentCountry.areaCode,
                cellphone: this.resetForm.mobileNumber.trim(),
                phoneCode: this.resetForm.verificationCode.trim(),
            }
            api.client_forgot_psd(params).then(res => {
                if (res.code == 200) {
                    this.showResetPsd = false
                    this.$notify({
                        title: this.$t('tips_success'),
                        message: this.isChangePassword ? this.$t('label_change_password') : this.$t('label_changeSuccess_msg'),
                        type: 'success',
                        position: 'top-right',
                    })
                    if (this.isChangePassword) {
                        this.$store.commit('SET_LOGIN_TOKEN', res.data)
                        this.$store.dispatch('setUserId')
                        this.$store.commit('SET_LOGIN_MODAL', false)
                    } else {
                        this.showDefaultLogin = true
                    }
                } else if (res.code == 20040007) {
                    this.$notify({
                        title: this.$t('tips_error'),
                        message: this.$t('label_unauthorized_account'),
                        type: 'error',
                        position: 'top-right',
                    })
                } else {
                    this.$notify({
                        title: this.$t('tips_error'),
                        message: res.message || 'System Error',
                        type: 'error',
                        position: 'top-right',
                    })
                }
            }).catch(err => {
                if (err.code == 1201) {
                    this.resetForm.isValidVerificationCode = false
                } else {
                    this.$notify({
                        title: this.$t('tips_error'),
                        message: err.message || 'System Error',
                        type: 'error',
                        position: 'top-right',
                    })
                }
            })
        }
    }

    clickForgetPsd() {
        this.resetForm.mobileNumber = this.psdLoginForm.mobileNumber
        this.resetForm.resetPsd = ''
        this.showDefaultLogin = false
        this.showResetPsd = true
    }

    // 切换国家
    changeCountry(item) {
        this.currentCountry = item
        const mobileRuleReg = new RegExp(this.currentCountry.mobileRule)
        this.otpLoginForm.isValidMobileNumber = this.otpLoginForm.mobileNumber && mobileRuleReg.test(this.otpLoginForm.mobileNumber)
        this.psdLoginForm.isValidMobileNumber = this.psdLoginForm.mobileNumber && mobileRuleReg.test(this.psdLoginForm.mobileNumber)
        this.resetForm.isValidMobileNumber = this.resetForm.mobileNumber && mobileRuleReg.test(this.resetForm.mobileNumber)
    }

    showProtocolModal(termsType) {
            if (this.$store.state.countryId == 1234) {
                this.$store.commit('SET_LOGIN_MODAL', false)
                this.$router.push(this.localePath({
                    name: 'vietnamPolicy',
                    query: {
                        selectedPolicyId: termsType == 13 ? 1 : 2,
                    },
                }))
                return
            }
            const res = policyData
            this.$store.commit('SET_PROTOCOL_LIST', res && res.data || [])
            this.$store.commit('SET_SELECTED_PROTOCOL_ID', termsType)
            this.$store.commit('SET_LOGIN_MODAL', false)
            this.$store.commit('SET_SELECTED_POLICY_ID', termsType + '')
            this.protocolList = res.data
            this.selectedPolicyId = termsType
            // 存到localstorage
            window.localStorage.setItem('protocolList', JSON.stringify(this.protocolList))
            window.localStorage.setItem('selectedPolicyId', termsType)
            let name
            termsType === 7 ? name = 'privacyPolicy' : name = 'termsAndConditions'
            this.$router.push(this.localePath({
            name,
        }))
    }

    @Watch('otpLoginForm.mobileNumber')
    onOtpMobileNumber(newVal) {
        if (!this.currentCountry.mobileRule) {
            return
        }
        this.otpLoginForm.isValidMobileNumber = newVal && new RegExp(this.currentCountry.mobileRule).test(newVal)
        this.otpLoginForm.isActiveUser = true
    }

    @Watch('psdLoginForm.mobileNumber')
    onPsdMobileNumber(newVal) {
        if (!this.currentCountry.mobileRule) {
            return
        }
        this.psdLoginForm.isValidMobileNumber = newVal && new RegExp(this.currentCountry.mobileRule).test(newVal)
        this.psdLoginForm.isRegisteredMobileNumber = true
    }

    @Watch('resetForm.mobileNumber')
    onResetMobileNumber(newVal) {
        if (!this.currentCountry.mobileRule) {
            return
        }
        this.resetForm.isValidMobileNumber = newVal && new RegExp(this.currentCountry.mobileRule).test(newVal)
    }

    onSignInSuccess(result) {
        const params = {
            thirdPartId: Number(result.authResponse.userID),
            thirdPartType: 1,
        }
        api.client_third_part_login(params).then(res => {
        let data = (res && res.data) || {}
        if (res.code == 200) {
            this.$store.commit('SET_LOGIN_TOKEN', data.token)
            this.$store.dispatch('setUserId')
            this.loginApiVerified = data.verified
            this.getProfile(data.token)
        } else if (res.code == 20040007) {
            this.$notify({
                title: this.$t('tips_error'),
                message: this.$t('label_unauthorized_account'),
                type: 'error',
                position: 'top-right',
            })
        } else {
            this.$notify({
                title: this.$t('tips_error'),
                message: res.message,
                type: 'error',
                position: 'top-right',
            })
        }
    }).catch(err => {
        if (err.code == 1201) {
            this.otpLoginForm.isValidVerificationCode = false
        }
    })
    }

    onSignInError() {
        this.$notify({
            title: this.$t('tips_error'),
            message: this.$t('label_unauthorized_account'),
            type: 'error',
            position: 'top-right',
        })
    }

    }

