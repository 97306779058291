





































































































































































































































































import {
    Vue, Component, Watch,
} from 'vue-property-decorator'
import {State} from 'vuex-class'
import Cookies from 'js-cookie'
import HeaderSearch from '~/components/header-search.vue'
import LangBox from '~/components/lang-box.vue'
import CountryBox from '~/components/country-box.vue'
import LoginBox from '~/components/login-box.vue'
import VerifyBox from '~/components/verify-box.vue'
import GuideVerifyBox from '~/components/guide-verify-box.vue'
import ProtocolBox from '~/components/protocol-box.vue'
import api from '~/api'
import common from '~/utils/common'
import utils from '~/utils'
import LoginBoxAddress from '~/components/login-box-address.vue'
import LoginAuditResult from '~/components/bosch/login-audit-result.vue'
import SearchFeedback from '~/components/search/search-feedback.vue'
import $config from '~/config'

const scrollFn = utils.throttle(function () {
    if (this.stickyHeader) {
        this.stickyHeader = document.documentElement.scrollTop > 0
    } else {
        this.stickyHeader = document.documentElement.scrollTop >= 68
    }
}, 300)

@Component({
    components: {
        HeaderSearch,
        LangBox,
        CountryBox,
        LoginBox,
        VerifyBox,
        GuideVerifyBox,
        ProtocolBox,
        LoginBoxAddress,
        LoginAuditResult,
        SearchFeedback,
    },
})
export default class CommonHeader extends Vue {
@State('userInfo') userInfo

// @State('token') token

@State('langCode') langCode

@State('curOrderProcedure') curOrderProcedure

@State('hotLineMap') hotLineMap

@State('contactEmailMap') contactEmailMap

@State('countryId') countryId

@State('isQuotationOrder') isQuotationOrder

@State('isAgreePolicy') isAgreePolicy

$refs!: {
    loginBox: any,
}

token:string = ''

shopOptions: any[] = []

orderSearchValue: string = ''

priceIdList: any[] = []

selectedPriceItem: any = {}

ncOption:any = {}

isShowKnowledgeCenter: boolean = false

stickyHeader: boolean = false

get sellerCenterWebUrl() {
    return $config.sellerCenterWeb[$config.env]
}

get activeStep() {
    if (this.curOrderProcedure === 4) {
        return 3
    }
    return this.curOrderProcedure
}

// 获取路由名称用于判断页面头部显示的内容
get routeName() {
    return this.$route.path
}

get currentPageType() {
    // 订单模块相关的页面+账期模块相关页面头部不显示三个搜索框
    if (this.$route.path.indexOf('order') > -1) {
        return 'order'
    }
    if (this.$route.path.indexOf('credit') > -1) {
        return 'credit'
    }
    if (this.$route.path.indexOf('myFavoriteList') > -1) {
        return 'favorite'
    }
    if (this.$route.path.indexOf('myPoint') > -1) {
        return 'myPoint'
    }
    return 'common'
}

get creditModuleHeaderDesc() {
    let path = this.$route.path
    if (path.indexOf('/credit/list') > -1) {
        return this.$t('label_my_credit_details')
    }
    if (path.indexOf('/credit/details') > -1) {
        return this.$t('label_credit_detail')
    }
    if (path.indexOf('/credit/history') > -1) {
        return this.$t('label_history')
    }
    return ''
}

mounted() {
    if (this.$route.query.tokenType === 'CIAM') {
        this.$router.push({
            query: {
                tokenType: 'CIAMLogined'
            } 
        })
       this.getCIAMInfo()
    }
    if (this.$store.state.token) {
        this.token = this.$store.state.token
        this.onToken(true)
    }
    api.get_show_knowledge().then(res => {
        this.isShowKnowledgeCenter = res.data.article
        this.$store.commit('SET_FAQ_STATUS', res.data.faq)
    })
    window.addEventListener('scroll', () => {
        scrollFn(this)
    })
}

getCIAMInfo() {
    const token = Cookies.get('token')
    const tokenType = Cookies.get('tokenType')
    api.client_login_userInfo({}, {token, tokenType}).then(res => {
            if (res.data && res.data.userId) {
                this.$store.commit('SET_USER_ID', res.data.userId)
            }
            if (res.code == 20049600) {
            // applyStatus对应枚举值说明：
            // 0 - 客户没有申请过，需要提交注册申请
            // 1 - 客户提交了申请，审核中
            // 2 - 审核通过
            // 3 - 拒绝申请
            api.kr_register_status({userId: res.data.userId}).then(res => {
                this.$store.commit('SET_COUNTRY_INFO', { countryId: res.data.countryId, countryCode: 'KR' })
                switch (+res.data.applyStatus) {
                    case 0:
                        this.$store.commit('SET_LOGIN_MODAL', true)
                        this.$refs.loginBox.isShowCompleteAddress = true
                        this.$refs.loginBox.showDefaultLogin = false
                        this.$refs.loginBox.finishAddressKR(res.data)
                        this.$refs.loginBox.addressForm.contactName = res.data.contactName
                        break
                    case 1:
                        this.$refs.loginBox.showApplyStatusKR(1)
                        break
                    case 2:
                        this.$refs.loginBox.normalLoginKR(res.data)
                        this.token = Cookies.get('token')
                        this.$forceUpdate()
                        break
                    case 3:
                        this.$store.commit('SET_APPLY_AGAIN_DATA', res.data)
                        this.$refs.loginBox.showApplyStatusKR(2)
                        break
                }
            })
            return
          }
          if (res.code == 200) {
                this.token = res.data.token
                this.$refs.loginBox.loginApiVerified = res.data.verified
                this.$refs.loginBox.getProfile(res.data.token)
                this.$store.commit('SET_LOGIN_TOKEN', res.data.token)
                this.$store.dispatch('setUserId')
                this.$store.commit('SET_ACCEPT_COOKIES_STATUS', true)
            } else if (res.code == 20040007) {
                this.$notify({
                    title: this.$t('tips_error'),
                    message: this.$t('label_unauthorized_account'),
                    type: 'error',
                    position: 'top-right',
                })
            } else {
                this.$notify({
                    title: this.$t('tips_error'),
                    message: res.message,
                    type: 'error',
                    position: 'top-right',
                })
            }
        })
}

showLoginModal() {
    if (!this.isAgreePolicy) {
        return
    }
    this.$store.commit('SET_LOGIN_MODAL', true)
}

handleAccountCommand(command) {
    if (command === 'verify') {
        this.$store.commit('SET_VERIFY_MODAL', true)
    }
    if (command === 'logout') {
        this.logout()
    }
    if (command === 'gotoMyCredit') {
        this.$router.push(this.localePath({
            name: 'creditListPage',
        }))
    }
    if (command === 'changePassword') {
        this.$store.commit('SET_LOGIN_MODAL', true)
        this.$store.commit('SET_CHANGE_PASSWORD', true)
    }
}

handlePriceIdCommand(command) {
    this.selectedPriceItem = command
    this.$store.commit('SET_PRICE_ITEM', JSON.stringify(this.selectedPriceItem))
    common.setPriceId(command.priceId)
    api.check_price({ priceId: command.priceId })
    setTimeout(() => {
        window.location.reload()
    }, 10)
}

logout() {
    if (this.$route.query.origin || sessionStorage.getItem('source')) {
        sessionStorage.removeItem('source');
        if(sessionStorage.getItem('targetPage')){
            sessionStorage.removeItem('targetPage');
        }
        this.$store.commit('CLEAR_DATA', this)
        Cookies.remove('tokenType')
    } else if (Cookies.get('tokenType') === 'CIAM') {
        api.logout_ciam({
            loginSource: 'EGG_MALL_WEB',
        }).then(res => {
            let resData = res.data
            if (res.code == 200) {
                api.client_auth_logout().then(() => {
                    this.$store.commit('CLEAR_DATA', this)
                    Cookies.remove('tokenType')
                    window.location.href = resData
                    window.event.returnValue = false
                })
            } else {
                this.$store.commit('CLEAR_DATA', this)
                common.removeAccountInfo()
                window.location.reload()
            }
        })
    } else {
        api.client_auth_logout().then(() => {
            this.$store.commit('CLEAR_DATA', this)
            Cookies.remove('tokenType')
        })
    }
}

goChat(){
    if(!this.token){
        this.$store.commit('SET_LOGIN_MODAL', true)
        return
    }
    this.$gochat()
}

openLineLInk(){
    window.open('https://bit.ly/LINEforweb')
}

openEmailLInk() {
    if (this.countryId != 1198) {
        window.open('mailto:customerservice@whats-egg.com')
    } else {
        window.open('mailto:info.aakorea@kr.bosch.com')
    }
}

openPhone() {
   window.open(`tel:${this.hotLineMap[this.countryId]}`)
}

@Watch('token')
onToken(newVal) {
    if (newVal) {
        api.get_price_list().then(res => {
            this.priceIdList = (res && res.data) || []
            if (this.priceIdList.length > 0) {
                if (window.localStorage.getItem('priceItem')) {
                    let priceItem = window.localStorage.getItem('priceItem') || '{}'
                    this.selectedPriceItem = JSON.parse(priceItem)
                } else {
                    this.selectedPriceItem = this.priceIdList[0]
                    common.setPriceId(this.selectedPriceItem.priceId)
                    this.$store.commit('SET_PRICE_ITEM', JSON.stringify(this.selectedPriceItem))
                }
            }
        })
    }
}

jumpToKnowledge() {
    this.$router.push(this.localePath({
        name: 'knowledgeCenter',
    }))
}
}
