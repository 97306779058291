import common from '~/utils/common.ts'
import storage from '~/utils/storage.ts'

export default {
    INIT_DATA(state) {     // 初始化存在localStorage中的state
        state.userInfo = JSON.parse(window.localStorage ? window.localStorage.getItem('userInfo') || '{}' : '{}')
        state.userInfo.triggerByLogin = false
        state.token = window.localStorage.getItem('token')
        const userId = window.localStorage.getItem('userId')
        state.userId = userId ? +userId : ''
        state.priceItem = JSON.parse(window.localStorage ? window.localStorage.getItem('priceItem') || '{}' : '{}')
        state.acceptCookiesStatus = window.localStorage.getItem('acceptCookiesStatus', state.acceptCookiesStatus)
        // state.countryCurrency = storage.getLocal('currentCountry').currencyUnit
        state.protocolList = JSON.parse(window.localStorage.getItem('protocolList'))
        state.selectedProtocolTermsType = window.localStorage.getItem('selectedPolicyId')
        state.selectedPolicyId = window.localStorage.getItem('selectedPolicyId')
        // state.currentCountry = storage.getLocal('currentCountry')
        window.localStorage.setItem('langCode', state.langCode)
    },
    CLEAR_DATA(state, data) {
        const tempUUID = window.localStorage.getItem('cusotmerUUID')
        state.token = ''
        state.userInfo = {}
        window.localStorage.clear()
        common.removeAccountInfo()
        data.$cookies.removeAll()
        window.location.href = `/${state.langCode}`
        window.localStorage.setItem('acceptCookiesStatus', state.acceptCookiesStatus)
        window.localStorage.setItem('cusotmerUUID',tempUUID)
    },
    SET_LOGIN_TOKEN(state, token) {
        // state.token = token
        common.setToken(token)
        if (!process.server) {
            window.localStorage.setItem('token', token)
        }
    },
    SET_USER_ID(state, userId) {
        state.userId = userId
        if (!process.server) {
            window.localStorage.setItem('userId', userId)
        }
    },
    SET_CUSTOMER_TYPE(state, customerType) {
        state.customerType = customerType
        if (!process.server) {
            window.localStorage.setItem('customerType', customerType)
        }
    },
    SET_LOGIN_USERINFO(state, userInfo) {
        state.userInfo = {...state.userInfo, ...userInfo}
        if (!process.server) {
            window.localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
        }
    },
    SET_LANG(state, locale) {
        if (state.locales.indexOf(locale) !== -1) {
            state.langCode = locale
            if (!process.server) {
                window.localStorage.setItem('langCode', state.langCode)
            }
        }
    },
    SET_COUNTRY_INFO(state, {
        countryId, countryCode, res,
    }) {
        state.countryId = +countryId
        state.countryCode = countryCode
        if (process.server) {
            res.cookie('countryId', countryId, {
                maxAge: 864000000,
            })
            res.cookie('countryCode', countryCode, {
                maxAge: 864000000,
            })
        } else {
            common.setCountryInfo({ countryId, countryCode })
        }
    },
    SET_COUNTRY_CURRENCY(state, currency) {
        state.countryCurrency = currency
    },
    SET_CURRENT_COUNTRY(state, country) {
        state.currentCountry = country
    },
    SET_PRICE_ITEM(state, priceItem) {
        state.priceItem = JSON.parse(priceItem)
        window.localStorage.setItem('priceItem', priceItem)
    },
    SET_3P_SCENE(state, value) {
        state.is3PScene = value
    },
    SET_URL_TOKEN(state, token) {
        state.urlToken = token
    },
    SET_CART_QUANTITY(state, quantity) {
        state.curCartQuantity = quantity
    },
    SET_LOGIN_MODAL(state, isShow) {
        state.loginModalVisible = isShow
    },
    SET_CHANGE_PASSWORD(state, isChangePassword) {
        state.isChangePassword = isChangePassword
    },
    SET_VERIFY_MODAL(state, isShow) {
        state.verifyModalVisible = isShow
    },
    SET_GUIDE_VERIFY_MODAL(state, isShow) {
        state.guideVerifyModalVisible = isShow
    },
    SET_PROTOCOL_MODAL(state, isShow) {
        state.protocolModalVisible = isShow
    },
    SET_PROTOCOL_LIST(state, list) {
        state.protocolList = list
    },
    SET_PROTOCOL_LIST_NET(state, list) {
        console.log('SET_PROTOCOL_LIST_NET', list)
        state.protocolListNet = list
    },
    SET_PROTOCOL_UPDATED_FLAG(state, isUpdated) {
        state.hasUpdatedProtocol = isUpdated
    },
    SET_SELECTED_PROTOCOL_ID(state, id) {
        state.selectedProtocolTermsType = id
    },
    SET_SELECTED_POLICY_ID(state, id) {
        state.selectedPolicyId = id
    },
    SET_CUR_ORDER_STEP(state, stepNum) {
        state.curOrderProcedure = stepNum
    },
    SET_CHAT_UNREAD(state, payload) {
        state.chatUnread = payload
    },
    updateAccountInfo(state, obj) {
        state.accountInfo = obj
        window.localStorage.setItem('yunxinAccountInfo', JSON.stringify(state.accountInfo))
    },
    SET_NIM(state, nim) {
        state.unreadNim = nim
    },
    SET_IS_QUOTATION_ORDER_STATUS(state, payload) {
        state.isQuotationOrder = payload
    },
    SET_QUOTATION_TO_ORDER_PARAMS(state, payload) {
        state.quotationParams = payload
        if (!process.server && payload) {
            window.localStorage.setItem('quotationParams', JSON.stringify(payload))
        }
    },
    SET_ACCEPT_COOKIES_STATUS(state, payload) {
        state.acceptCookiesStatus = payload
        if (!process.server) {
            window.localStorage.setItem('acceptCookiesStatus', state.acceptCookiesStatus)
        }
    },
    // SET_COUNTTYLIST_INFO(state, payload) {
    //     state.countryListInfo = payload
    // },
    SET_COUNTRY_LIST(state,list){
        state.countryList = list
    },
    SET_CUSOTMER_UUID(state, cusotmerUUID) {
        state.cusotmerUUID = cusotmerUUID
    },
    SET_WEBP(state,payload){
        state.canUseWebpFormat = payload
    },
    SET_LOGIN_ADDRESS(state, isShow) {
        state.loginAddressVisible = isShow
    },
    SET_LOGIN_STATUS(state, payload) {
        state.firstLogin = payload
    },
    SET_POLICY_STATUS(state,payload){
        state.isAgreePolicy = payload
    },
    SET_SUBSCRIBE_STATUS(state,payload){
        state.subscribeDialog = payload
    },
    SET_FAQ_STATUS(state,payload){
        state.isShowFAQ = payload
    },
    SET_AUDIT_RESULT_STATUS(state,payload){
        state.loginAuditResultVisible = payload.loginAuditResultVisible
        state.auditStatus = payload.auditStatus
    },
    SET_APPLY_AGAIN_DATA(state,payload){
        state.applyAgainData = payload
    },
    SET_LOGIN_COMPLETE_ADDRESS(state,payload){
        state.isRepeatApply = payload
    },
    SET_MODAL_STATUS(state,payload){
        state.openSearchKeywords = payload
    },
    SET_OE_PARTS_BRAND(state,payload){
        state.oePartsBrand = payload
    },
    SET_LANDING_PAGE_INFO(state,payload){
        state.landingPageInfo = payload
    },
    SET_FEEDBACK_STATUS(state,payload){
        state.searchFeedbackModalVisible = payload
    },
    SET_FEEDBACK_CONTENT(state,payload){
        state.searchFeedbackContent = payload
    }
}
