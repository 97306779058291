

















































































































    import {
        Vue, Component, Watch, Prop,
    } from 'vue-property-decorator'
    import {State} from 'vuex-class'
    import SearchProductsBox from '~/components/search/search-products-box.vue'
    import SearchOeNoBrandSKUBox from '~/components/search/search-oeNo-brandSKU.vue'
    import SearchVinCodeVehicleTypeBox from '~/components/search/search-vinCode-vehicleType.vue'

@Component({
    components: {
        SearchProductsBox,
        SearchOeNoBrandSKUBox,
        SearchVinCodeVehicleTypeBox,
    },
})
    export default class HeaderSearch extends Vue {
    @Prop({ default: '139px' }) top!: string

    @State('token') token

    @State('isAgreePolicy') isAgreePolicy

    @State('openSearchKeywords') openSearchKeywords

    @State('userInfo') userInfo

    $refs!: {
        searchVinCodeVehicleTypeBox: any;
        searchOeNoBrandSKUBox: any;
        searchProductsBox: any;
    }

    options: any[] = []

    searchModalVisible: boolean = false

    searchProductsBoxFooterVisible: boolean = true

    curSearchType: string = ''

    mounted() {
    }

    /**
     * 打开搜索弹框
     * @param type {String} 搜索方式,可以根据vin code&车型/OE number&brand SKU/商品关键字，取值为vehicle|oeSKU|keywords
     */
    openSearchModal(type: string) {
        if (!this.isAgreePolicy) {
            return
        }
        if (this.curSearchType === type) {
            return
        }
        this.curSearchType = type;
        (document.querySelector('.common-header') as HTMLElement).style.zIndex = '3000'
        this.searchModalVisible = true
        this.$nextTick(() => {
            switch (type) {
                case 'vehicle':
                    this.$refs.searchVinCodeVehicleTypeBox.init()
                    break
                case 'oeSKU':
                    this.$refs.searchOeNoBrandSKUBox.init()
                    break
                case 'keywords':
                    this.$refs.searchProductsBox.init()
                    break
                default:
                    break
            }
        })
    }

    closeSearchModal() {
        this.curSearchType = ''
        this.$nextTick(() => {
            (document.querySelector('.common-header') as HTMLElement).style.zIndex = '1000'
        })
        this.$store.commit('SET_MODAL_STATUS', false)
        this.$store.commit('SET_OE_PARTS_BRAND', {})
    }

    resetSearchProductInput() {
        this.$refs.searchProductsBox.resetFormInput()
    }

    executeSearchProuduct() {
        this.$refs.searchProductsBox.executeSearch()
    }

    executeSearchForTest(val){
        this.$refs.searchProductsBox.executeSearchForTest(undefined, val)
    }

    goChat() {
        this.searchModalVisible = false
        setTimeout(() => {
            this.$gochat()
        }, 300)
    }

    @Watch('openSearchKeywords', { immediate: true })
    openSearchKeywordsFunc(newVal) {
        if (newVal) {
            this.openSearchModal('keywords')
            this.$store.commit('SET_MODAL_STATUS', false)
        }
    }
    }
