













































































































































































import {
    Vue, Component, Watch,
} from 'vue-property-decorator'
import { State } from 'vuex-class'
import api from '~/api'
import { encryptPassword } from '~/utils/encryption'
import common from '~/utils/common'
import storage from '~/utils/storage'
import CommonCheckPasswordInput from '~/components/common-check-password-input.vue'
import CaptchaVerify from '~/components/CaptchaVerify.vue'
import policyData from '~/utils/policy.ts'
import utils from '~/utils'

@Component({
    components: {
        CommonCheckPasswordInput,
        CaptchaVerify,
    },
})

export default class LoginBoxAddress extends Vue {
@State('langCode') langCode

@State('loginAddressVisible') loginAddressVisible

@State('token') token

activeStep:number = 0

currentCountry: any = {                    // 当前选择的国家
    id: '',
    countryFlag: '',
    areaCode: '',
    mobileRule: null,
}

countryList: any[] = []                    // 国家列表

otpLoginForm: any = {
    mobileNumber: '',
    isValidMobileNumber: false,
    verificationCode: '',
    isValidVerificationCode: true,
    showTextInSendBtn: '',
    timer: null,
    isActiveUser: true,
    isActiveUserTips: '',
}

sendCodeType: string = 'login'

captchaVerifyVisible:boolean = false

loginApiVerified: number = 0

addressForm: any = {
    contactName:'',
    detailAddress:'',
    district:'',
    postCode: ''
}

addressFormRules: any = {
    contactName:[
        { required: true, message: this.$t('label_contact_placeholder'), trigger: 'blur' }
    ],
    detailAddress:[
        { required: true, message: this.$t('label_detail_address_placeholder'), trigger: 'blur' }
    ],
    district:[
       { type: 'array', required: true, message: this.$t('label_district_placeholder'), trigger: ['change','blur'] }
    ],
    postCode:[
        { required: true, message: this.$t('label_post_code_fail'), trigger: 'blur' }
    ]
}

addressList:any =  []

needPostCode:boolean = false

isShowPostCodeList:boolean = false
            
postCodeList: any = []

get isDisableLogin() {
    return !this.otpLoginForm.isValidMobileNumber
        || !this.otpLoginForm.verificationCode
        || !this.otpLoginForm.isActiveUser
}

checkActiveUser(){
    if (!this.otpLoginForm.isValidMobileNumber) {
        return
    }
    let params = {
        cellphone: this.otpLoginForm.mobileNumber.trim(),
        prefix: this.currentCountry.areaCode,
        checkType: 3
    }
    api.checkout_user_active_status(params).then(res => {
        if(res.code != 200){
            this.otpLoginForm.isActiveUser = false
            this.otpLoginForm.isActiveUserTips = res.message
        }
    })
}

closeDlg() {
    this.$store.commit('SET_LOGIN_ADDRESS', false)
    if(this.activeStep == 1 && this.token){
        window.location.reload()
    }
}

getCountryList() {
    this.countryList = this.$store.state.countryList
    this.currentCountry = this.$store.state.currentCountry
}

fillVerificationCode() {
    if (this.otpLoginForm.verificationCode.length === 4) {
        utils.fbq('trackCustom', 'OTP_fill_in')
        utils.ga4('event', 'OTP_fill_in')
    }
}

visibleChange(value){
    if(value && this.addressForm.district.length != 3){
        this.$message.warning(this.$t('label_post_code_fail2'))
    }
}

openDlg(){
    // 重置表单内容
    const data = <any> this.$options.data
    Object.assign(this.$data, data.call(this))
    this.getCountryList()
    if(this.token){
        api.is_show_post_code().then((res)=>{
            this.needPostCode = res.data
        })
        this.activeStep = 1
    }
    if(this.token){
        api.district_list_get().then(res => {
            let data = (res && res.data) || []
            this.addressList = data
        })
    }
}


selectDistrict(){
    this.addressForm.postCode = ''
    if(this.addressForm.district.length != 3) return
    this.setPostCode(this.addressForm.district[2])
}

setPostCode(id){
    api.get_post_code({districtId:id}).then((res)=>{
        if(res.data.length === 1){
            this.addressForm.postCode = res.data[0]
            let addressFormRefSet = this.$refs.addressFormRef as any
            addressFormRefSet.validate()
            this.postCodeList = res.data
        }else{
            this.postCodeList = res.data
        }
    })
}

clearMobileInput(type) {
    this[type].mobileNumber = ''
}

sendVerificationCode(type) {
    this.sendCodeType = type
    let operatedForm = this.otpLoginForm
    if (operatedForm.timer) {
        return
    }
    this.captchaVerifyVisible = true
}

doSendCode(captchaData) {
    let operatedForm = this.otpLoginForm
    if (this.sendCodeType === 'login') {
        utils.fbq('trackCustom', 'OTP_request')
        utils.ga4('event', 'OTP_request')
    }
    let params: any = {
        ...captchaData,
        prefix: '+' + this.currentCountry.areaCode,
        cellphone: operatedForm.mobileNumber.trim(),
        cellphoneCode: encryptPassword(operatedForm.mobileNumber.trim()),
    }
    api.client_send_code(params).then(res => {
        let code = +res.code
        this.$notify({
            title: code === (200 || 20049024) ? this.$t('tips_success') : this.$t('tips_error'),
            message: res.message,
            type: code === (200 || 20049024) ? 'success' : 'error',
            position: 'top-right',
        })
        if (code !== (200 || 20049024)) {
            operatedForm.showTextInSendBtn = this.$t('setting_Resend')
            return
        }

        operatedForm.showTextInSendBtn = 60
        operatedForm.timer = setInterval(() => {
            operatedForm.showTextInSendBtn--
            if (operatedForm.showTextInSendBtn === 0) {
                operatedForm.showTextInSendBtn = this.$t('setting_Resend')
                clearInterval(operatedForm.timer)
                operatedForm.timer = null
            }
        }, 1000)
    })
}
// 切换国家
changeCountry(item) {
    this.currentCountry = item
    const mobileRuleReg = new RegExp(this.currentCountry.mobileRule)
    this.otpLoginForm.isValidMobileNumber = this.otpLoginForm.mobileNumber && mobileRuleReg.test(this.otpLoginForm.mobileNumber)
    this.$store.commit('SET_COUNTRY_INFO', { countryId:+this.currentCountry.id, countryCode:this.currentCountry.countryCode })
}

@Watch('otpLoginForm.mobileNumber')
onOtpMobileNumber(newVal) {
    if (!this.currentCountry.mobileRule) {
        return
    }
    this.otpLoginForm.isValidMobileNumber = newVal && new RegExp(this.currentCountry.mobileRule).test(newVal)
    this.otpLoginForm.isActiveUser = true
}

loginByOtp() {
    if(this.currentCountry.countryCode === "KR"){
        this.loginByOtpKR()
        return
    }
    this.otpLoginForm.isValidVerificationCode = true
    let params = {
        cellphone: this.otpLoginForm.mobileNumber,
        prefix: this.currentCountry.areaCode,
        phoneCode: this.otpLoginForm.verificationCode,
    }
    api.client_login_otp(params).then(res => {
        let data = (res && res.data) || {}
        if (res.code == 20040007) {
            this.$notify({
                title: this.$t('tips_error'),
                message: this.$t('label_unauthorized_account'),
                type: 'error',
                position: 'top-right',
            })
            return
        }
        if (res.code != 200) {
            this.$notify({
                title: this.$t('tips_error'),
                message: res.message,
                type: 'error',
                position: 'top-right',
            })
            return
        }
        utils.fbq('trackCustom', 'account_registration', {
            customerId: data.userId,
            registrationSource: 'website',
        })
        utils.ga4('event', 'account_registration', {
            customerId: data.userId,
            registrationSource: 'website',
        })
        this.loginApiVerified = data.verified 
        window.localStorage.setItem('loginApiVerified',data.verified)
        this.$store.commit('SET_LOGIN_TOKEN', data.token)
        this.$store.dispatch('setUserId')
        api.client_profile({token:this.token}).then(userData => {
            this.$store.commit('SET_LOGIN_USERINFO', {
                ...userData.data.profile,
                triggerByLogin: true,
                loginApiVerified: this.loginApiVerified,
            })
        })
        // 判断是否有地址            
        if(res.data.existAddress){ 
            this.getProfile()
        }else{
            api.is_show_post_code().then((res)=>{
                this.needPostCode = res.data
            })
            // 这里要经常token的国家处理
            this.activeStep = 1
            api.district_list_get().then(res => {
                let data = (res && res.data) || []                    
                this.addressList = data
            })
        }
        this.$store.commit('SET_ACCEPT_COOKIES_STATUS', true)
    }).catch(err => {
        if (err.code == 1201) {
            this.otpLoginForm.isValidVerificationCode = false
        }
    })
}

loginByOtpKR(){
    this.otpLoginForm.isValidVerificationCode = true
    let params = {
        cellphone: this.otpLoginForm.mobileNumber,
        prefix: this.currentCountry.areaCode,
        phoneCode: this.otpLoginForm.verificationCode,
    }
    api.client_login_otp(params).then(res=>{
        if(res.data && res.data.userId){
            this.$store.commit('SET_USER_ID', res.data.userId)
        }
        if(res.code == 20049600){
            // applyStatus对应枚举值说明：
            // 0 - 客户没有申请过，需要提交注册申请
            // 1 - 客户提交了申请，审核中
            // 2 - 审核通过
            // 3 - 拒绝申请
            api.kr_register_status({userId:res.data.userId}).then((res)=>{
                switch(+res.data.applyStatus) {
                    case 0:
                        this.addressForm.contactName = res.data.contactName
                        this.activeStep = 1
                        api.district_list_get().then(res => {
                            let data = (res && res.data) || []                    
                            this.addressList = data
                        })
                        break;
                    case 1:
                        this.showApplyStatusKR(1)
                        break;
                    case 2:
                        this.normalLoginKR(res.data)
                        break;
                    case 3:
                        this.$store.commit('SET_APPLY_AGAIN_DATA', res.data)
                        this.showApplyStatusKR(2)
                        break;
                }
            })
            return
        }
        if (res.code != 200) {
            this.$notify({
                title: this.$t('tips_error'),
                message: res.message,
                type: 'error',
                position: 'top-right',
            })
            return
        }
        // 正常登录
        this.normalLoginKR(res.data)
    })
}

getProfile(token?: string) {
    this.$store.commit('SET_LOGIN_MODAL', false)
    let params:any = {}
    if (token) {
        params.token = token
    }
    api.client_profile(params).then(userData => {
        this.$store.commit('SET_LOGIN_USERINFO', {
            ...userData.data.profile,
            triggerByLogin: true,
            loginApiVerified: this.loginApiVerified,
        })
        this.loginSuccessCallBack()
    }).catch(err => {
        this.$notify({
            title: this.$t('tips_error'),
            message: err.message || 'System Error',
            type: 'error',
            position: 'top-right',
        })
    })
}

loginSuccessCallBack() {
    let countryId = this.$store.state.userInfo.countryId
    let countryCode = this.countryList.find(item => item.id === countryId).countryCode
    this.$store.commit('SET_COUNTRY_INFO', { countryId, countryCode })
    api.get_last_check_price().then(res => {
        if (res.code == 200) {
            let priceIdList = (res && res.data) || []
            let lastCheckPriceIndex = priceIdList.findIndex(item => item.lastCheck)
            if (lastCheckPriceIndex > -1) {
                common.setPriceId(priceIdList[lastCheckPriceIndex].priceId)
                this.$store.commit('SET_PRICE_ITEM', JSON.stringify(priceIdList[lastCheckPriceIndex]))
                if (this.$route.path.indexOf('bannerPromotion') > -1) { // 未登录显示banner进入banner详情h5后唤起登录窗口登录场景
                    let promotionUrl = this.$route.query.url
                    promotionUrl = promotionUrl.replace('token=', `token=${window.localStorage.getItem('token')}`)
                    promotionUrl = promotionUrl.replace('priceId=', `priceId=${this.$store.state.priceItem.priceId}`)
                    this.$router.replace({
                        path: this.localePath({ name: 'bannerPromotionPage' }),
                        query: {
                            url: promotionUrl,
                        },
                    })
                    setTimeout(() => {
                        window.location.reload()
                    }, 50)
                } else {
                    setTimeout(() => {
                        window.location.reload()
                    }, 10)
                }
            } else {
                api.client_auth_logout().then(() => {
                    this.$store.commit('CLEAR_DATA', this)
                })
            }
        }
    })
}

finishAddress(){
    let addressFormRefSet = this.$refs.addressFormRef as any
    addressFormRefSet.validate((valid) => {
        if (valid) {
            let params = {
                cellphone: this.otpLoginForm.mobileNumber || this.$store.state.userInfo.cellphone,
                cityId: this.addressForm.district[1],
                contactName: this.addressForm.contactName,
                detailAddress: this.addressForm.detailAddress,
                districtId: this.addressForm.district[2],
                stateId: this.addressForm.district[0],
                postCode: this.addressForm.postCode
            }     
            let paramsKR = {
                prefix: "82",
                cellphone: this.otpLoginForm.mobileNumber || this.$store.state.userInfo.cellphone,
                cityId: this.addressForm.district[1],
                contactName: this.addressForm.contactName,
                detailAddress: this.addressForm.detailAddress,
                districtId: this.addressForm.district[2],
                stateId: this.addressForm.district[0],
                userId: this.$store.state.userId,
                countryId: 1198
            }    
            if(this.currentCountry.countryCode === "KR"){
                api.kr_apply_register(paramsKR).then(()=>{
                    this.showApplyStatusKR(1)
                })      
            }else{
                api.add_address(params).then(()=>{
                    this.getProfile()
                    this.$store.commit('SET_LOGIN_ADDRESS', false)
                })
            } 
            api.add_address(params).then(()=>{
                this.getProfile()
                this.$store.commit('SET_LOGIN_ADDRESS', false)
            })
            }            

        })
}

showApplyStatusKR(status){
    this.$store.commit('SET_LOGIN_ADDRESS', false)
    if(+status === 2){
        this.$store.commit('SET_AUDIT_RESULT_STATUS',{loginAuditResultVisible:true,auditStatus:false})
    }else{
        this.$store.commit('SET_AUDIT_RESULT_STATUS', {loginAuditResultVisible:true,auditStatus:true})
    }
}
normalLoginKR(data){
    this.$store.commit('SET_LOGIN_TOKEN', data.token)
    this.$store.commit('SET_ACCEPT_COOKIES_STATUS', true)
    this.$store.commit('SET_LOGIN_MODAL', false)
    api.client_profile({token:data.token}).then(userData => {
        this.$store.commit('SET_LOGIN_USERINFO', {
            ...userData.data.profile,
            triggerByLogin: true,
        })
        let countryId = this.$store.state.userInfo.countryId
        let countryCode = this.countryList.find(item => item.id === countryId).countryCode
        this.$store.commit('SET_COUNTRY_INFO', { countryId, countryCode })
        setTimeout(() => {
            window.location.reload()
        }, 50)
    })
}    

}

