import { method } from './var'

export default {
    client_auth_login: {
        method: method.Post,
        url: '/client/auth/login',
    },
    client_profile: {
        method: method.Get,
        cache: false,
        url: '/client/profile',
    },
    cms_banners_pc_find: {
        method: method.Get,
        url: '/web-api/v1/index/bannerList',
    },
    client_auth_logout: {
        method: method.Get,
        url: '/client/auth/logout',
    },
    client_goodsCategory_all: {
        method: method.Get,
        url: '/cms/eggMallWeb/notLogin/getEggCategory',
    },

    // Fast Moving
    fast_moving_list: {
        method: method.Get,
        url: '/cms/vehicle/getCommonCategory',
    },
    fast_moving_detail: {
        method: method.Post,
        url: '/web-api/material/search',
    },
    client_username_check: {
        method: method.Get,
        url: '/client/register/isRegister/cellphone',
    },
    product_brands_get: {
        method: method.Get,
        url: '/web-api/v1/index/brandList',
    },
    vehicle_brand_get: {
        method: method.Get,
        url: '/app-api/vehicle/getBrandList',
    },
    vehicle_serial_list_get: {
        method: method.Get,
        url: '/app-api/vehicle/getSerialListByBrandId',
    },
    vehicle_yearCapacity_get: {
        method: method.Get,
        url: '/app-api/vehicle/getYearCapacityListBySerialId',
    },
    product_detail_get: {
        method: method.Get,
        url: '/client/version2/goods/new/detail',
    },
    search_first_eggCategory_get: {
        method: method.Get,
        url: '/app/search2/searchFirstEggCategory',
    },
    search_second_oeCategory_get: {
        method: method.Post,
        url: '/app/search2/searchSecondOeCategory',
    },
    vehicle_categoryList_oe: {
        method: method.Post,
        url: '/app-api/v4/material/listByOe',
    },
    vehicle_categoryList_iam: {
        method: method.Post,
        url: '/app-api/v4/material/listByIam',
    },
    vehicle_category_list: {
        method: method.Post,
        url: '/app-api/v4/material/list',
    },
    vehicle_vinCode_get: {
        method: method.Get,
        url: '/cms/vehicle/searchByVinCode',
    },
    product_list_get: {
        method: method.Post,
        url: '/web-api/material/search',
    },
    home_shop_list: {
        method: method.Get,
        url: '/web-api/v1/index/shopList',
    },
    shop_list_get: {
        method: method.Get,
        url: '/eggshop/client/page/keywords',
    },
    shop_detail_get: {
        method: method.Get,
        url: '/eggshop/client/info/{shopId}',
    },
    client_send_code: {
        method: method.Post,
        url: '/app-api/v1/phoneCode/send',
    },
    client_login_otp: {
        method: method.Post,
        url: '/app-api/v1/login/opt',
    },
    client_login_userPsd: {
        method: method.Post,
        url: '/app-api/v1/login/password',
    },
    client_login_userInfo: {
        method: method.Get,
        url: '/app-api/v1/login/queryUserLoginInfo',
    },
    client_set_psd: {
        method: method.Post,
        url: '/app-api/v1/login/resetPassword',
    },
    client_forgot_psd: {
        method: method.Post,
        url: '/app-api/v1/login/forgetPassword',
    },
    province_get: {
        method: method.Get,
        url: '/web-api/address/province',
    },
    city_get: {
        method: method.Get,
        url: '/web-api/address/city',
    },
    district_get: {
        method: method.Get,
        url: '/web-api/address/district',
    },
    workshop_verify_post: {
        method: method.Post,
        url: '/app-api/v1/login/verified',
    },
    fileUrl_upload: {
        method: method.Post,
        url: '/web-api/file/uploadCertification',
    },
    // 下单
    shopping_cart_quantity: {
        method: method.Get,
        url: '/web-api/shoppingCart/cartQuantity',
    },
    shopping_cart_list: {
        method: method.Get,
        url: '/web-api/shoppingCart/list',
    },
    update_sku_quantity_in_cart: {
        method: method.Put,
        url: '/web-api/v6/shoppingCart/quantity',
    },
    update_delivery_time_in_cart: {
        method: method.Post,
        url: '/web-api/shoppingCart/selectDeliveryTime',
    },
    del_sku_in_cart: {
        method: method.Delete,
        url: '/web-api/v6/shoppingCart/delete',
    },
    clear_invalid_product: {
        method: method.Delete,
        url: '/web-api/v6/shoppingCart/delete',
    },
    add_product_into_cart: {
        method: method.Post,
        url: '/web-api/v5/shoppingCart/add',
    },
    get_vehicle_more: {
        method: method.Post,
        url: '/app-api/vehicle/application/more',
    },
    check_order: {
        method: method.Post,
        url: '/web-api/shoppingCart/toOrder',
    },
    get_all_address_list: {
        method: method.Get,
        url: '/web-api/address/list',
    },
    get_ship_by_list: {
        method: method.Get,
        url: '/web-api/shipBy/list',
    },
    add_address: {
        method: method.Post,
        url: '/web-api/address/save',
    },
    place_order: {
        method: method.Post,
        url: '/web-api/order/submitOrder',
    },
    get_order_result: {
        method: method.Post,
        url: '/web-api/order/successResult',
    },
    calc_order_price: {
        method: method.Post,
        url: '/app-api/order/calc/price',
    },
    get_online_payment_prepare: {
        method: method.Post,
        url: '/app-api/order/getOnlinePayment/h5',
    },
    get_bank_transfer_info: {
        method: method.Get,
        url: '/web-api/account/info',
    },
    get_order_list: {
        method: method.Get,
        url: '/app/material/order/myOrders',
    },
    get_order_detail: {
        method: method.Get,
        url: '/client/version2/order/detail/{id}',
    },
    get_returned_order_list: {
        method: method.Get,
        url: '/app-api/saleReturnOrder/page',
    },
    get_returned_order_detail: {
        method: method.Get,
        url: '/client/saleReturnOrder/detail/{orderId}',
    },
    get_price_list: {
        method: method.Get,
        url: '/web-api/price/list',
    },
    get_last_check_price: {
        method: method.Get,
        url: '/app/price/identity/getUserIdentity',
    },
    check_price: {
        method: method.Post,
        url: '/app/price/identity/user/check/last',
    },
    agreement_list_get: {
        method: method.Get,
        url: '/web-api/agreement/list',
    },
    agreement_get: {
        method: method.Get,
        url: '/web-api/agreement/getByTermsType',
    },
    check_overdue: {
        method: method.Post,
        url: '/web-api/profile/isOverdue',
    },
    country_list_get: {
        method: method.Get,
        url: '/app-api/v1/country/list',
    },
    get_language_list_by_country: {
        method: method.Get,
        url: '/app-api/countryLanguage',
    },
    get_yunxin_info: {
        method: method.Get,
        url: '/account/getToken',
        localApiUrlPrefix: '/imserver',
    },
    get_shop_yunxin_info: {
        method: method.Post,
        url: '/conversation/customerInitiate?shopId={shopId}',
        localApiUrlPrefix: '/imserver',
    },
    confirm_price_change: {
        method: method.Post,
        url: '/app-api/order/confirmModifyPrice',
    },
    confirm_redeem_code: {
        method: method.Post,
        url: '/web-api/myCoupon/exchange/{code}',
    },
    select_coupon_list: {
        method: method.Get,
        url: '/web-api/myCoupon',
    },
    get_coupon_summary: {
        method: method.Get,
        url: '/web-api/myCoupon/summary',
    },
    shopping_cart_refresh: {
        method: method.Put,
        url: '/web-api/v6/shoppingCart/selectStatus',
    },
    credit_list_get: {
        method: method.Get,
        url: '/app-api/bill/summary',
    },
    credit_detail_get: {
        method: method.Get,
        url: '/creditInvoiceBillRecord/myBill',
        localApiUrlPrefix: '/credit',
    },
    credit_bill_record_detail_get: {
        method: method.Post,
        url: '/app-api/bill/getBillRecordDetail',
    },
    credit_bill_overdue_list_by_shop: {
        method: method.Get,
        url: '/creditInvoiceBillRecord/overdueList',
        localApiUrlPrefix: '/credit',
    },
    credit_bill_duePay_list_by_shop: {
        method: method.Get,
        url: '/app-api/bill/duePayAmountDetail',
    },
    credit_history_list: {
        method: method.Get,
        url: '/creditInvoiceBillRecord/historyList',
        localApiUrlPrefix: '/credit',
    },
    credit_history_detail_monthly: {
        method: method.Get,
        url: '/creditInvoiceBillRecord/searchMonthList',
        localApiUrlPrefix: '/credit',
    },
    my_favorite_list: {
        method: method.Get,
        url: '/app-api/v4/goodsCollect',
    },
    my_favorite_cancel: {
        method: method.Put,
        url: '/app-api/v4/goodsCollect/cancel/skuOrgId/{skuOrgId}',
    },
    my_favorite_collect: {
        method: method.Post,
        url: '/app-api/v4/goodsCollect/save',
    },
    get_paymentMethod_list: {
        method: method.Post,
        url: '/app-api/order/changePaymentMethod/forShipByType',
    },
    update_log_toB: {
        method: method.Get,
        url: '/app-api/shoppingCart/noteToBLog',
    },
    update_log_guide_verify: {
        method: method.Put,
        url: '/app-api/v1/login/verified/airshow',
    },
    get_recommend_goods_page: {
        method: method.Get,
        url: '/client/homePage/getRecommendGoodsPage',
    },
    get_you_may_like: {
        method: method.Post,
        url: '/web/v1/product/recommendYouMayAlsoLike',
    },
    get_updated_list_agreement: {
        method: method.Get,
        url: '/web-api/agreement/listNeedAgreement',
    },
    get_common_config: {
        method: method.Get,
        url: '/commons/config',
    },
    operate_agreement: {
        method: method.Post,
        url: '/app-api/agreement/agree',
    },
    thirdP_vehicle_search_list_get: {
        method: method.Post,
        url: '/app-api/v4/material/repair/listByCommon',
    },
    thirdP_home_shop_list: {
        method: method.Get,
        url: '/web-api/v1/index/queryBoschAllianceShopInfo',
    },
    thirdP_shop_list_get: {
        method: method.Get,
        url: '/eggshop/client/allianceShopPage',
    },
    product_recommend_you_may_like: {
        method: method.Get,
        url: '/web/v1/product/recommendYouMayLike',
    },
    product_comment_list: {
        method: method.Get,
        url: '/web/v1/product/getComment',
    },
    get_search_history: {
        method: method.Get,
        url: '/app/search2/getSearchHistory',
    },
    get_accurate_search_result: {
        method: method.Post,
        url: '/app-api/v4/material/accurateSearch',
    },
    get_vehicle_search_recommendation: {
        method: method.Get,
        url: '/cms/vehicle/getSearchRecommendations',
    },
    get_vehicle_search_brandName_or_categoryName: {
        method: method.Post,
        url: '/app-api/vehicle/search/brandNameOrCategoryName',
    },
    get_vehicle_search_serial: {
        method: method.Post,
        url: '/app-api/vehicle/search/vehicleSerial',
    },
    get_vehicle_search_more: {
        method: method.Post,
        url: '/app-api/vehicle/search/vehicleSerial/more',
    },
    get_password_pattern: {
        method: method.Get,
        url: '/universe/passwordPattern',
        localApiUrlPrefix: '/landcruiser',
    },
    logout_ciam: {
        method: method.Get,
        url: '/ciam/logoutUrl',
        localApiUrlPrefix: '/landcruiser',
    },
    get_popular_models: {
        method: method.Post,
        url: '/app-api/vehicle/getPopularModels',
    },
    get_oe_brandsku: {
        method: method.Get,
        url: '/app-api/v4/material/search/oeNumberOrBrandSku',
    },
    get_multiField: {
        method: method.Get,
        url: '/app-api/v4/material/search/multiField',
    },
    // 主页的搜索
    client_brand_seach: {
        method: method.Get,
        url: '/client/brand',
    },
    client_third_part_login: {
        method: method.Post,
        url: '/app-api/v1/login/thirdPartLogin',
    },
    // 主页新promotion页面
    get_new_promotion: {
        method: method.Get,
        url: '/cms/promotionLandingPage/getHomePageInfo',
    },
    get_new_promotion_detail: {
        method: method.Get,
        url: '/cms/promotionLandingPage/{id}/detail',
    },
    get_coupon_landing_page: {
        method: method.Post,
        url: '/cms/promotionLandingPage/{promotionLandingPageId}/getCouponInLandingPage',
    },
    get_more_promotion_product: {
        method: method.Post,
        url: '/cms/promotionLandingPage/{promotionLandingPageId}/detail/pageQuerySku',
    },
    get_product_stock: {
        method: method.Get,
        url: '/client/version2/goods/getEggBuyAvailableStock',
    },
    district_list_get: {
        method: method.Get,
        url: '/web-api/address/provinceTree',
    },
    policy_agree: {
        method: method.Post,
        url: '/app-api/v5/agreement/agree',
    },
    get_customer_policy_status: {
        method: method.Get,
        url: '/app-api/v5/agreement/check',
    },
    get_egg_shop_product_stock: {
        method: method.Get,
        url: '/client/version2/goods/getEggShopAvailableStock',
    },
    submit_knowledge_info: {
        method: method.Post,
        url: '/knowledge/submit',
    },
    get_article_list: {
        method: method.Get,
        url: '/knowledge/article/pageQuery',
    },
    get_FAQ_category: {
        method: method.Get,
        url: '/knowledge/faq/questionCategory',
    },
    get_FAQ_detail: {
        method: method.Get,
        url: '/knowledge/faq/query',
    },
    get_hot_tags: {
        method: method.Get,
        url: '/knowledge/tag',
    },
    get_hot_article_category: {
        method: method.Get,
        url: '/knowledge/articleCategory',
    },
    get_random_FAQ: {
        method: method.Get,
        url: '/knowledge/faq/list',
    },
    get_article_detail: {
        method: method.Get,
        url: '/knowledge/article/{id}',
    },
    get_article_detail_products: {
        method: method.Get,
        url: '/knowledge/article/{id}/recommendInfo',
    },
    get_show_knowledge: {
        method: method.Get,
        url: '/knowledge/status',
    },
    get_reorder: {
        method: method.Get,
        url: '/client/version2/order/again/{orderId}',
    },
    kr_apply_register: {
        method: method.Post,
        url: '/client/register/apply',
    },
    kr_register_status: {
        method: method.Get,
        url: '/app/profile/apply/info?userId={userId}',
    },
    kr_cancel_reasons: {
        method: method.Get,
        url: '/client/version2/order/cancel/reason/{orderId}',
    },
    kr_cancel_order: {
        method: method.Post,
        url: '/client/version2/order/apply/cancel/submit',
    },
    kr_stop_cancel_order: {
        method: method.Get,
        url: '/client/version2/order/cancel/apply/accept?orderId={orderId}',
    },
    get_points: {
        method: method.Get,
        url: '/points',
    },
    get_points_list: {
        method: method.Get,
        url: '/points/record',
    },
    kr_vincode_search: {
        method: method.Get,
        url: '/cms/vehicle/kr/searchByVinCode',
    },
    kr_vincode_save: {
        method: method.Get,
        url: '/cms/vehicle/recordSearchHistory',
    },
    get_redeem_list: {
        method: method.Get,
        url: '/points/redemption/pageQuery',
    },
    get_redeem_detail: {
        method: method.Get,
        url: '/points/redemption/{id}',
    },
    get_points_record: {
        method: method.Get,
        url: '/points/record',
    },
    get_points_TH: {
        method: method.Get,
        url: '/points',
    },
    redeem_redemption: {
        method: method.Post,
        url: '/points/redemption/{id}',
    },
    get_points_rules: {
        method: method.Get,
        url: '/points/rule',
    },
    get_points_behavior: {
        method: method.Get,
        url: '/points/rule/behavior',
    },
    get_scb_qr_code: {
        method: method.Get,
        url: '/app-api/v1/payment/scb/qrcode',
    },
    is_show_post_code: {
        method: method.Get,
        url: '/web-api/address/postCode/config',
    },
    get_post_code: {
        method: method.Get,
        url: '/web-api/address/district/{districtId}/postCode',
    },
    get_scb_qr_code: {
        method: method.Get,
        url: '/app-api/v1/payment/scb/qrcode',
    },
    get_relate_goods: {
        method: method.Get,
        url: '/client/version2/goods/recommendSameShop',
    },
    get_common_search_key: {
        method: method.Get,
        url: '/app-api/v4/material/search/common',
    },
    get_brand_list: {
        method: method.Get,
        url: '/app-api/v4/material/search/brand',
    },
    get_category_list: {
        method: method.Get,
        url: '/app-api/v4/material/search/category',
    },
    get_vehicle_list: {
        method: method.Get,
        url: '/app-api/vehicle/list',
    },
    get_coupon_list: {
        method: method.Post,
        url: '/app-api/v4/shoppingCart/coupon/list',
    },
    get_newcomer_coupon: {
        method: method.Post,
        url: '/cms/promotionLandingPage/{promotionLandingPageId}/getNewCustomerCoupon?couponId={couponId}',
    },
    get_customer_discount: {
        method: method.Get,
        url: '/cms/promotionLandingPage/{promotionLandingPageId}/calculateGoodsInShoppingCartAmount',
    },
    get_oe_parts: {
        method: method.Get,
        url: '/web-api/v1/index/oeZoneBrandList',
    },
    get_oe_parts_image: {
        method: method.Get,
        url: '/client/oeBanner',
    },
    login_CIAM: {
        method: method.Get,
        url: '/ciam/loginUrl',
        localApiUrlPrefix: '/landcruiser',
    },
    receive_sign: {
        method: method.Post,
        url: '/client/version2/order/sellerDelivery/customerInvoice', 
    },    
    feedback_question:{
        method: method.Post,
        url: '/app-api/v4/material/search/feedback', 
    }, 
    feedback_question_list:{
        method: method.Get,
        url: '/app-api/v4/material/search/feedback/promotionType', 
    }, 
    checkout_user_active_status:{
        method: method.Get,
        url: '/app-api/profile/checkCellphoneBeforeLogin', 
    },
    search_reference_options:{
        method: method.Post,
        url: '/web-api/material/search/ref', 
    },
    event_tracking:{
        method: method.Post,
        url: '/web-api/v1/eventTracking/batchSave', 
        localApiUrlPrefix: '/coaster',
    },
    search_keywords:{
        method: method.Get,
        url: '/web-api/material/search/correctKeyword', 
    },
    get_qr_payment_status:{
        method: method.Get,
        url: '/web-api/order/paymentResult',
    }, 
    get_logined_info:{
        method: method.Get,
        url: '/app-api/profile/info',
    },
    get_vin_code_tips: {
        method: method.Get,
        url: '/cms/vehicle/getVinSearchSupportBrandList',
    },
    collect_category: {
        method: method.Post,
        url: '/client/categoryCollect/collect',
    },
    cancel_collect_category: {
        method: method.Post,
        url: '/client/categoryCollect/cancel',
    },
    get_collect_category_list: {
        method: method.Post,
        url: '/client/categoryCollect/page',
    },
    getRecommendBoughtProductPage: {
        method: method.Post,
        url: '/client/activity/pageBoughtProductOnPromotion',
    },
    getRecommendNewProductPage: {
        method: method.Get,
        url: '/client/activity/newArrivalSku/list',
    },
    getLandingPageSkuFilter: {
        method: method.Get,
        url: '/cms/promotionLandingPage/{promotionLandingPageId}/skuFilter',
    },
}
