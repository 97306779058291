





































































































    import {
        Vue, Component
    } from 'vue-property-decorator'
    import api from '~/api'
    import SelectVehicleTypeSteps from '~/components/common-select-vehicle-by-steps.vue'
    import { getSearchHistory } from '~/api/search'
    import utils from '~/utils'
    import KRVincodeSearch from '~/components/kr-vincode-search.vue'
    import {State} from 'vuex-class'

@Component({
    components: {
        SelectVehicleTypeSteps,
        KRVincodeSearch
    },
})
    export default class SearchVinCodeVehicleTypeBox extends Vue {
        @State('token') token

        $refs!: {
            selectVehicleTypeSteps: any;
        }

        vinNumberInput: string = ''

        isShowHistoryPage: boolean = false

        historyDataVinCode: any[] = []

        popularModelsList: any[] = []

        getPopularModelsParams: any = {}

        occupiedWidth:number = 0

        listElementGroupHtml: any[] = []

        isKRVincodeSearch: boolean = true

        vinCodeList: any[] = []

        loading: boolean = false

        vinCodeBrandList: any = ''

        init() {
            this.$refs.selectVehicleTypeSteps.getVehicleBrand()
            this.getSearchHistory()
            this.getPopularModels()
            api.get_vin_code_tips().then(res=>{
                if(res.code == 200 && res.data){
                    this.vinCodeBrandList = `${this.$t('label_vin_code_tips')} ${res.data}`
                }
            })
        }

        showHistoryPage() {
            this.isShowHistoryPage = true
        }

        handleClickVinCode(item){
            if(this.token){
                api.kr_vincode_save({vinCode:item.vinCode, vehicleModelId:item.vehicleModelId})
            }
            this.vinCodeJump(item.vinCode,item)
        }

        preSearch(vinCode){
            this.loading = true
            api.kr_vincode_search({vinCode}).then((res)=>{
                this.loading =false
                if(res.code != 200){
                    this.$message.error(res.message)
                    return
                }
                if(res.data.length >= 2 ){
                    this.vinCodeList = res.data
                    this.isKRVincodeSearch = false
                }else{
                    if(this.token){
                        api.kr_vincode_save({vinCode:res.vinCode, vehicleModelId:res.vehicleModelId})
                    }
                    this.vinCodeJump(vinCode,res.data[0])
                }
            })
        }

        searchByVinCode(vinCode) {
            if (!vinCode || !vinCode.trim()) {
                return
            }
            if(sessionStorage.getItem('vehicleAdditionalData')){
                sessionStorage.removeItem('vehicleAdditionalData')
            }
            let customizeValue = {
                searchTime: new Date().getTime(),
                vinCode: vinCode.trim(),
                matchingMethod: 'Manual input'
            };
            this.batchSave(customizeValue, 11);
            if (!/^\w{17}$/.test(vinCode)) {
                this.$notify({
                    title: this.$t('tips_error'),
                    message: this.$t('tips_not_vin_title') + '\n' + this.$t('tips_not_vin_msg'),
                    type: 'error',
                    position: 'top-right',
                })
            } else {
                utils.fbq('track', 'Search', {
                    search_string: vinCode,
                })
                if(this.$store.state.countryId == 1198){
                    this.preSearch(vinCode)
                    return
                }
                api.vehicle_vinCode_get({ vinCode }).then(res => {
                    // 无搜索结果时不跳转下一个页面
                    if (+res.code === 20046000 || +res.code === 404) {
                        this.$message({
                            customClass: 'common-customized-tip-message',
                            showClose: false,
                            message: this.$t('label_vincode_search_no_result'),
                            type: 'warning',
                            offset: 155,
                        })
                        this.batchSave({...customizeValue, ...{failureReason: res.message, matchingResult: 'Failed'}}, 111);
                        return
                    }
                    this.batchSave({...customizeValue, ...{failureReason: '', matchingResult: 'Successful'}}, 111);
                    this.vinCodeJump(vinCode,res.data)
                }).catch(err => {
                    if (err) {
                        this.$message({
                            customClass: 'common-customized-tip-message',
                            showClose: false,
                            message: this.$t('label_vincode_search_no_result'),
                            type: 'warning',
                            offset: 155,
                        })
                        this.batchSave({...customizeValue, ...{failureReason: this.$t('label_vincode_search_no_result'), matchingResult: 'Failed'}}, 111);
                    }
                })
            }
        }

        vinCodeJump(vinCode,res){
            let data = res.eggVehicleKeyDescription || ''
            let id = res.vehicleModelId
            let vehicleBrandName = res.vehicleBrandName
            let metaParams = {
                vehicleType: data,
            }
            let navParams
            if(this.$store.state.countryId == 1198){
                navParams = vinCode + ' ' + res.oeBrandName + ' ' + data
            }else{
                navParams = vinCode + ' ' + vehicleBrandName + ' ' + data       
            }
            if(res.vehicleAdditionalData){
                sessionStorage.setItem('vehicleAdditionalData',JSON.stringify(res.vehicleAdditionalData))
            }
            if(!res.vehicleAdditionalData && sessionStorage.getItem('vehicleAdditionalData')){
                sessionStorage.removeItem('vehicleAdditionalData')
            }

            this.$router.push(this.localePath({
                name: 'selectCategoryPage',
                params: {
                    vehicleModelId: id,
                },
                query: {
                    vehicleSerialId: res.vehicleSerialId,
                    vehicleModelId: id,
                    searchCategoryInfo: JSON.stringify(metaParams),
                    vehicleCategoryType: JSON.stringify(navParams),
                    sourcePage: 'Search VIN Code'
                },
            }))
            this.$emit('update:visible', false)
        }

        getSearchHistory() {
            this.historyDataVinCode = []
            this.$axios.$get(getSearchHistory('searchTypeList=1&searchTypeList=2'), {}).then(res => {
                if (res && res.data) {
                    this.historyDataVinCode = res.data.filter(item => item.searchType === 'VIN_CODE')
                    this.$nextTick(() => {
                        let listElementGroup = document.querySelectorAll('.item-father')
                        this.listElementGroupHtml = [...listElementGroup]
                        this.listElementGroupHtml.map(item => {
                            this.occupiedWidth += parseInt(window.getComputedStyle(item).width)
                        })
                    })
                }
            })
        }

        selectSearchHistory(content) {
            if (content.searchType === 'VIN_CODE') {
                this.searchByVinCode(content.searchValue)
            } else {
                this.gotoSelectCategoryPage(content.vehicleModelId, content.searchValue)
            }
        }

        gotoSelectCategoryPage(vehicleModelId, words) {
            if (!vehicleModelId) {
                return
            }
            let params = {
                vehicleModelId,
            }
            let metaParams = {
                vehicleType: words,
            }
            this.$router.replace(this.localePath({
                name: 'selectCategoryPage',
                params,
                query: {
                    searchCategoryInfo: JSON.stringify(metaParams),
                    vehicleCategoryType: JSON.stringify(words),
                    vehicleModelId,
                    sourcePage: 'Vehicle Type'
                },
            }))
            this.$emit('update:visible', false)
        }

        selectVehicleModel({words, node}) {
            if(sessionStorage.getItem('vehicleAdditionalData')){
                sessionStorage.removeItem('vehicleAdditionalData')
            }
            // let words = this.selectedLevelNodeList[0].label + ' ' + node.eggVehicleKeyDescription
            let metaParams = {
                vehicleType: words,
            }

            utils.fbq('track', 'Search', {
                search_string: words,
            })

            this.$router.replace(this.localePath({
                name: 'selectCategoryPage',
                params: {
                    vehicleModelId: node.vehicleModelId,
                },
                query: {
                    searchCategoryInfo: JSON.stringify(metaParams),
                    vehicleCategoryType: JSON.stringify(words),
                    vehicleModelId: node.vehicleModelId,
                    sourcePage: 'Vehicle Type'
                },
            }))
            this.$emit('update:visible', false)
        }

        openMoreHistory() {
            let domHistory = document.querySelector('.history-vin-code') as HTMLElement
            domHistory.className += ' show-history-more'
            let domImg = document.querySelector('.fold-open') as HTMLElement
            domImg.style.display = 'none'
        }

        closeMoreHistory() {
            let domHistory = document.querySelector('.history-vin-code') as HTMLElement
            domHistory.className = 'history-vin-code'
            let domImg = document.querySelector('.fold-open') as HTMLElement
            domImg.style.display = 'block'
        }

        getPopularModels() {
            this.getPopularModelsParams = {
                page: 1,
                limit: 6,
                condition: {},
            }
            api.get_popular_models(this.getPopularModelsParams).then(res => {
                this.popularModelsList = (res && res.data) || []
            })
        }

        getMorePopularModels() {
            this.getPopularModelsParams.page++
            api.get_popular_models(this.getPopularModelsParams).then(res => {
                this.popularModelsList = (res && res.data) || []
            })
        }

        batchSave(customizeValue, elementId) {
            let params = {
                datas: [{
                    appId: 'EGG_MALL_WEB',
                    appVersion: 'EGG_MALL_WEB',
                    carrier: 'EGG_MALL_WEB',
                    customizeValue,
                    deviceId: 'EGG_MALL_WEB',
                    dynamicResourceType: 1,
                    elementId,
                    eventTime: 1,
                    eventType: 1,
                    ip: 'EGG_MALL_WEB',
                    isFirstTime: 1,
                    latitude: 'EGG_MALL_WEB',
                    libVersion: 'EGG_MALL_WEB',
                    longitude: 'EGG_MALL_WEB',
                    manufacturer: 'EGG_MALL_WEB',
                    model: 'EGG_MALL_WEB',
                    networkType: 'EGG_MALL_WEB',
                    operatingSystem: 'WEB',
                    operatingSystemVersion: 'EGG_MALL_WEB',
                    referPage: 'EGG_MALL_WEB',
                    screenHeight: 'EGG_MALL_WEB',
                    screenName: 'EGG_MALL_WEB',
                    screenOrientation: 1,
                    screenWidth: 'EGG_MALL_WEB',
                    sessionId: 'EGG_MALL_WEB',
                    timeZone: 'EGG_MALL_WEB',
                    userId: this.$store.state.userId || 0,
                    businessPlatform: 11,
                }],
            }
            api.event_tracking(JSON.stringify(params))
        }
    }
